<template>
  <div>
    <div v-for="(message, messageIndex) in chatHistory" :key="messageIndex">
      <ERow v-if="message.isArray" class="e-w-full e-justify-end">
        <v-card
          flat
          :max-width="$vuetify.breakpoint.mdAndUp ? '100%' : '70%'"
          color="#F5F5F5"
          class="e-w-full"
        >
          <v-card-title class="body-2 font-weight-medium">
            chatGPT
          </v-card-title>
          <v-card-text class="caption mb-1 font-weight-medium text-darken-1">
            <v-data-table
              :items="message.content"
              :headers="headers"
              class="hazards-table"
              hide-default-footer
            >
              <template #item.concerns="{ item }">
                <div class="py-2">
                  {{ item.concerns }}
                </div>
              </template>
              <template #item.actions="{ item }">
                <ul class="py-2">
                  <li v-for="(action, i) of item.actions" :key="i">
                    {{ action }}
                  </li>
                </ul>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </ERow>
      <ERow v-else>
        <ECol>
          <ERow :justify="message.role === 'user' ? 'start' : 'end'">
            <v-card
              flat
              max-width="750"
              :color="message.role === 'user' ? '#FCFCFC' : '#F5F5F5'"
            >
              <v-card-title class="body-2 font-weight-medium">{{
                message.role === "user" ? "YOU" : "chatGPT"
              }}</v-card-title>
              <v-card-text
                class="caption mb-1 font-weight-medium text-darken-1"
              >
                {{ message.content }}
              </v-card-text>
            </v-card>
          </ERow>
        </ECol>
      </ERow>
    </div>
    <div
      v-if="isProcessing"
      class="d-flex flex-column justify-center align-center pa-4"
    >
      <div class="caption-1">
        {{ $t("content.recordings.brain_tool_processing") }}
      </div>
      <div class="d-flex justify-center py-4">
        <EvercamLoadingAnimation size="FourXl" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default {
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    chatHistory: {
      type: Array,
      required: true,
    },
    isProcessing: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      displayResponse: "",
      completedTyping: false,
      headers: [
        {
          value: "hazardCategory",
          text: "Hazard Category",
          width: 50,
          sortable: true,
          filterable: true,
        },
        {
          value: "concerns",
          text: "Concerns",
          width: 75,
          filterable: false,
          sortable: false,
        },
        {
          value: "actions",
          text: "Actions / Checklist items",
          width: 120,
          filterable: false,
          sortable: false,
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.hazards-table {
  border: 1px solid var(--dash-border-color);
}
</style>
