var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[(_vm.isProcessing)?_c('div',{staticClass:"d-flex flex-column justify-center align-center pa-4"},[_c('div',{staticClass:"caption-1"},[_vm._v("\n      "+_vm._s(_vm.$t("content.recordings.brain_tool_processing"))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"d-flex justify-center py-4"},[_c('EvercamLoadingAnimation',{attrs:{"size":"FourXl"}})],1)]):(_vm.error)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-alert',{attrs:{"type":"error","width":"250"}},[_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")])],1):_c('div',{staticClass:"d-flex px-4"},[_c('v-slider',{attrs:{"label":"Transparency"},model:{value:(_vm.transparency),callback:function ($$v) {_vm.transparency=$$v},expression:"transparency"}})],1),_vm._v(" "),_vm._l((_vm.segments),function(segment){return _c('div',{key:'segment-' + (segment.label + 1),staticClass:"segment-label d-flex"},[_c('img',{class:{ hovered: segment.isHovered },style:({
        width: _vm.calculateWidth(segment.polygon) + 'px',
        height: _vm.calculateHeight(segment.polygon) + 'px',
        margin: '0.5rem auto',
        maxWidth: '75%',
        maxHeight: '60px',
      }),attrs:{"src":_vm.cutImage(segment.polygon)},on:{"mouseenter":function($event){return _vm.handleImageHover(segment, true)},"mouseleave":function($event){return _vm.handleImageHover(segment, false)}}})])}),_vm._v(" "),_c('portal',{attrs:{"to":"evercam-boxes-container"}},[(_vm.segments.length)?_c('div',{staticClass:"w-100 h-100 position-absolute t-0",attrs:{"id":"segments"}},[_c('svg',{style:(_vm.svgStyles),attrs:{"viewbox":`0 0 ${_vm.imageDimensions.width} ${_vm.imageDimensions.height}`,"height":_vm.imageDimensions.height,"width":_vm.imageDimensions.width,"preserveAspectRatio":"none"}},_vm._l((_vm.segments),function(segment){return _c('polygon',{key:segment.label,attrs:{"points":_vm.formatPoints(segment.polygon),"fill":_vm.highlightedIndex === segment.label
              ? 'yellow'
              : _vm.stringToColor(segment.label),"stroke":"black"},on:{"mouseenter":function($event){return _vm.handleImageHover(segment, true)},"mouseleave":function($event){return _vm.handleImageHover(segment, false)}}})}),0)]):_vm._e()])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }