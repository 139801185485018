export default {
  CONTINUOUS: {
    Monday: ["00:00-23:00"],
    Tuesday: ["00:00-23:00"],
    Wednesday: ["00:00-23:00"],
    Thursday: ["00:00-23:00"],
    Friday: ["00:00-23:00"],
    Saturday: ["00:00-23:00"],
    Sunday: ["00:00-23:00"],
  },
  WORKING_HOURS: {
    Monday: ["08:00-18:00"],
    Tuesday: ["08:00-18:00"],
    Wednesday: ["08:00-18:00"],
    Thursday: ["08:00-18:00"],
    Friday: ["08:00-18:00"],
    Saturday: [],
    Sunday: [],
  },
  FULL: {
    Monday: ["00:00-23:59"],
    Tuesday: ["00:00-23:59"],
    Wednesday: ["00:00-23:59"],
    Thursday: ["00:00-23:59"],
    Friday: ["00:00-23:59"],
    Saturday: ["00:00-23:59"],
    Sunday: ["00:00-23:59"],
  },
  EMPTY: {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  },
}

export const Second = 1000

export const twentyThreeHours = 23 * 60 * 60

export const timeToNumber = (time: string): number => {
  const [hours, minutes] = time.split(":")

  return parseInt(hours) * 60 + parseInt(minutes)
}

export const numberToTime = (number: number): string => {
  const hours = Math.floor(number / 60)
  const minutes = number % 60

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`
}

export function toNumericSchedule(
  schedule: Record<string, string[]>
): Record<string, number[]> {
  return Object.entries(schedule).reduce((acc, [day, hours]) => {
    return {
      ...acc,
      [day]: hours.length
        ? hours[0].split("-").map((h) => Number.parseInt(h.split(":")[0]))
        : [0, 23],
    }
  }, {})
}

export function subtractOneHourFromEndIntervals(
  schedule: Record<string, string[]>
): Record<string, string[]> {
  return Object.fromEntries(
    Object.entries(schedule).map(([day, hours]) => [
      day,
      hours.map((interval) => {
        const [start, end] = interval.split("-")
        let [endHour, endMinute]: [string | number, string | number] =
          end.split(":") as [string, string]
        endHour = parseInt(endHour)

        endHour = Math.max(0, endHour - 1)

        return `${start}-${endHour.toString().padStart(2, "0")}:${endMinute}`
      }),
    ])
  )
}

export const SECOND = 1000
