<template>
  <v-tooltip
    :disabled="!showTooltip"
    right
    nudge-top="55"
    nudge-left="58"
    transition="none"
    color="#000"
  >
    <template #activator="{ on }">
      <div
        class="player-actions__btn player-actions__play-pause__box"
        :class="{
          'mx-2 ml-4': sm,
          'player-actions__play-pause--pause': isPlaying,
          'v-btn--disabled opacity-30': disabled,
          'ml-3 mx-1': xs,
        }"
        v-on="on"
        @click="$emit('click')"
      ></div>
    </template>
    <span class="player-actions__text" :class="{ 'mx-1': !isPlaying }">
      {{ isPlaying ? "Pause" : "Play" }}
    </span>
  </v-tooltip>
</template>

<script lang="ts">
import Vue from "vue"

export default Vue.extend({
  name: "PlayPauseButton",
  props: {
    showTooltip: {
      type: Boolean,
      default: true,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style scoped lang="scss">
$btn-size: 12px;
.player-actions {
  position: relative;
  &__btn {
    cursor: pointer;
    transition: 0.2s;
    filter: drop-shadow(0 0 5px #0009);
    user-select: none;
    &:hover {
      filter: drop-shadow(0 0 4px rgba(255, 255, 255, 0.67));
    }
  }
  &__text {
    text-shadow: 0 0 4px rgb(0 0 0 / 75%);
    user-select: none;
    .v-icon.v-icon--link {
      color: white !important;
    }
  }
  &__play-pause {
    &__box {
      width: $btn-size * 2;
      height: $btn-size * 2;
      box-sizing: border-box;
      border-style: solid;
      border-width: $btn-size 0 $btn-size $btn-size * 1.8;
      border-color: transparent transparent transparent white;
      transition: border-width 150ms ease-out;
    }
    &--pause {
      border-style: double;
      border-width: 0 0 0 $btn-size * 1.8;
    }
  }
}

.evercam--mobile {
  .player-actions__play-pause {
    $btn-size: 0.5rem;
    &__box {
      width: $btn-size * 2;
      height: $btn-size * 2;
      border-width: $btn-size 0 $btn-size $btn-size * 1.8;
    }
    &--pause {
      border-width: 0 0 0 $btn-size * 1.8;
    }
  }
}
</style>
