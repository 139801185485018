import { NvrModel, CameraModel } from "@evercam/shared/types"

export default {
  NVRMODELS: [
    {
      exid: NvrModel.YCam,
      value: NvrModel.YCam,
      name: "Y-Cam",
      pictureEndpoint: "/snapshot.jpg",
      streamingEndpoint: "/live_h264.sdp",
      playback_url: null,
    },
    {
      exid: NvrModel.Nxwitness,
      value: NvrModel.Nxwitness,
      name: "NxWitness",
      pictureEndpoint: "/ec2/cameraThumbnail?cameraId={device_id}&time=now",
      streamingEndpoint: "/{device_id}?code=h264&resolution=480p",
      playbackUrl:
        "/hls/{device_id}.mkv?pos={starttime}&duration={duration}&hi=true",
    },
    {
      exid: NvrModel.Mobotix,
      value: NvrModel.Mobotix,
      name: "Mobotix",
      pictureEndpoint: "/record/current.jpg?size=3840x2160",
      streamingEndpoint: null,
      playback_url: null,
    },
    {
      exid: NvrModel.Hikvision,
      value: NvrModel.Hikvision,
      name: "Hikvision",
      pictureEndpoint:
        "/ISAPI/Streaming/channels/{device_id}/picture?videoResolutionWidth=1920&videoResolutionHeight=1080",
      streamingEndpoint: "/ISAPI/Streaming/channels/{device_id}/av_stream",
      playback_endpoint:
        "/Streaming/tracks/{device_id}?starttime={startdate}&endtime={enddate}",
    },
    {
      exid: NvrModel.Dahua,
      value: NvrModel.Dahua,
      name: "Dahua",
      pictureEndpoint: "/cgi-bin/snapshot.cgi?channel={device_id}",
      streamingEndpoint: "/cam/realmonitor?channel={device_id}&subtype=1",
      playback_url:
        "/cam/playback?channel={device_id}&starttime={startdate}&endtime={enddate}",
    },
    {
      exid: NvrModel.Axis,
      value: NvrModel.Axis,
      name: "Axis",
      pictureEndpoint: "/axis-cgi/jpg/image.cgi",
      streamingEndpoint: "/axis-media/media.amp?resolution=800x600",
      playback_url: null,
    },
    {
      exid: NvrModel.Avigilion,
      value: NvrModel.Avigilion,
      name: "Avigilon",
      pictureEndpoint: "/media/cam0/still.jpg?res=max",
      streamingEndpoint: "/defaultPrimary?mtu=1440&streamType=u",
      playback_url: null,
    },
    {
      exid: NvrModel.Hanwha,
      value: NvrModel.Hanwha,
      name: "Hanwha",
      pictureEndpoint: "/snapshot.jpg",
      streamingEndpoint: "/live_h264.sdp",
    },
    {
      exid: NvrModel.ExNvr,
      value: NvrModel.ExNvr,
      name: "ExNvr",
      pictureEndpoint: "/api/devices/{device_id}/snapshot",
      streamingEndpoint: "/api/devices/{device_id}/hls/index.m3u8",
    },
  ],
  CAMERAMODELS: [
    {
      exid: CameraModel.YCam,
      value: CameraModel.YCam,
      name: "Y-Cam",
      pictureEndpoint: "/snapshot.jpg",
      streamingEndpoint: "/live_h264.sdp",
      playback_url: null,
    },
    {
      exid: CameraModel.Other,
      value: CameraModel.Other,
      name: "Other",
      pictureEndpoint: "",
      streamingEndpoint: "",
      playback_url: null,
    },
    {
      exid: CameraModel.Mobotix,
      value: CameraModel.Mobotix,
      name: "Mobotix",
      pictureEndpoint: "/record/current.jpg?size=3840x2160",
      streamingEndpoint: null,
      playback_url: null,
    },
    {
      exid: CameraModel.Hikvision,
      value: CameraModel.Hikvision,
      name: "Hikvision",
      pictureEndpoint:
        "/ISAPI/Streaming/channels/{device_id}/picture?videoResolutionWidth=1920&videoResolutionHeight=1080",
      streamingEndpoint: "/ISAPI/Streaming/channels/{device_id}/av_stream",
      playback_endpoint:
        "/Streaming/tracks/{device_id}?starttime={startdate}&endtime={enddate}",
    },
    {
      exid: CameraModel.Dahua,
      value: CameraModel.Dahua,
      name: "Dahua",
      pictureEndpoint: "/cgi-bin/snapshot.cgi?channel={device_id}",
      streamingEndpoint: "/cam/realmonitor?channel={device_id}&subtype=1",
      playback_url:
        "/cam/playback?channel={device_id}&starttime={startdate}&endtime={enddate}",
    },
    {
      exid: CameraModel.Axis,
      value: CameraModel.Axis,
      name: "Axis",
      pictureEndpoint: "/axis-cgi/jpg/image.cgi",
      streamingEndpoint: "/axis-media/media.amp?resolution=800x600",
      playback_url: null,
    },
    {
      exid: CameraModel.Avigilion,
      value: CameraModel.Avigilion,
      name: "Avigilon",
      pictureEndpoint: "/media/cam0/still.jpg?res=max",
      streamingEndpoint: "/defaultPrimary?mtu=1440&streamType=u",
      playback_url: null,
    },
    {
      exid: CameraModel.Hanwha,
      value: CameraModel.Hanwha,
      name: "Hanwha",
      pictureEndpoint: "/snapshot.jpg",
      streamingEndpoint: "/live_h264.sdp",
    },
    {
      exid: CameraModel.Milesight,
      value: CameraModel.Milesight,
      name: "Milesight",
      pictureEndpoint: "/snapshot.cgi",
      streamingEndpoint: "/main",
    },
  ],
}
