<template>
  <!-- ProgressBar -->
  <div
    ref="progressBar"
    class="player-progress cursor-pointer w-100 py-6 pt-6"
    @click="onProgressBarClick"
    @mouseenter="onMouseEnter"
    @mousemove="onMouseMove"
    @mouseleave="onMouseLeave"
  >
    <div class="player-progress__bar cursor-pointer">
      <!-- Bar background -->
      <div class="player-progress__background w-100"></div>

      <!-- Preloaded chunks indicators -->
      <span
        v-for="[start, end] in preloadedChunks"
        :key="end"
        class="player-progress__chunk player-progress__chunk--preloaded"
        :style="getChunkStyle(start, end)"
      />

      <!-- Hover indicator -->
      <div
        :style="hoverBarStyle"
        class="player-progress__hover-bar"
        :class="{ 'player-progress__hover-bar--hidden': !hovered }"
      ></div>

      <div
        v-if="ticks && frames.length < 300"
        class="player-progress__ticks d-flex justify-space-between align-center z-index-3 w-100"
      >
        <div
          v-for="(_, index) in frames"
          :key="index"
          class="player-progress__tick"
        ></div>
      </div>

      <!-- Progress indicator -->
      <div class="player-progress__progress" :style="progressStyle">
        <span
          class="player-progress__cursor"
          :style="cursorStyle"
          @pointerdown="onCursorMouseDown"
          @pointerup="onCursorMouseUp"
        ></span>
      </div>
    </div>

    <!-- Makers -->
    <ImagePlayerMarkers
      v-if="events.length"
      :frames="frames"
      :events="events"
      :tooltip-style="{ top: hovered ? '-27px' : '-12px' }"
      v-bind="$attrs"
      @marker-active="(marker) => $emit('marker-active', marker)"
      @marker-reset="(marker) => $emit('marker-reset', marker)"
      @marker-selected="(marker) => $emit('marker-selected', marker)"
    />

    <!-- tooltip -->
    <div
      :style="tooltipPosition"
      class="player-progress__tooltip white--text font-weight-medium mt-2"
      :class="{ 'player-progress__tooltip--hidden': !hovered }"
    >
      <slot
        name="tooltip"
        :hovered-timestamp="hoveredFrameData.timestamp"
      ></slot>
      {{ hoveredFrameData.label }}
    </div>
  </div>
</template>

<script>
import { timeouts } from "@evercam/ui"
import ImagePlayerMarkers from "@evercam/shared/components/imagePlayer/ImagePlayerMarkers"

export default {
  name: "PlayerProgressBar",
  components: { ImagePlayerMarkers },
  mixins: [timeouts],
  props: {
    frames: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    preloadedFrames: {
      type: Array,
      default: () => [],
    },
    frameIndex: {
      type: Number,
      default: 0,
    },
    ticks: {
      type: Boolean,
      default: true,
    },
    isLive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
      progressBar: null,
      isDraggingCursor: false,
      mousePosition: {
        x: 0,
        y: 0,
      },
    }
  },
  computed: {
    hoveredFrameData() {
      return this.frames[this.hoveredFrameIndex] || {}
    },
    mouseToWidthRatio() {
      return (
        this.mousePosition.x / this.progressBar?.getBoundingClientRect()?.width
      )
    },
    tooltipPosition() {
      let tooltipWidth = this.$vuetify.breakpoint.mdAndDown ? "65" : "155"
      let marginLeft
      if (this.mousePosition.x < tooltipWidth / 2) {
        marginLeft = "7%"
      } else if (
        this.progressBar?.getBoundingClientRect()?.width -
          this.mousePosition.x <
        tooltipWidth / 2
      ) {
        marginLeft = "93%"
      } else {
        marginLeft = `${100 * this.mouseToWidthRatio}%`
      }

      return {
        width: `${tooltipWidth}px`,
        left: marginLeft,
        pointerEvents: "none",
        bottom: "8px",
      }
    },
    hoverBarStyle() {
      return {
        width: `${100 * this.mouseToWidthRatio}%`,
      }
    },
    progressStyle() {
      const currentFrameIndex = this.isDraggingCursor
        ? this.hoveredFrameIndex
        : this.frameIndex

      if (this.isLive) {
        return "100%"
      }

      return {
        width: `${100 * (currentFrameIndex / (this.frames.length - 1))}%`,
      }
    },
    cursorStyle() {
      return {
        opacity: this.hovered ? 1 : 0,
      }
    },
    hoveredFrameIndex() {
      return Math.round(this.mouseToWidthRatio * (this.frames.length - 1))
    },
    preloadedChunks() {
      const preloadedFrames = [...this.preloadedFrames]
      preloadedFrames.sort((a, b) => {
        return a > b ? 1 : -1
      })

      return preloadedFrames.reduce((acc, frameIndex) => {
        if (!acc.length) {
          return [[frameIndex, frameIndex]]
        }

        const [start, end] = acc.slice(-1)[0] || [0, 0]
        const discontinuity = frameIndex - end > 1
        if (discontinuity) {
          return [...acc, [frameIndex, frameIndex]]
        }

        return [...acc.slice(0, -1), [start, frameIndex]]
      }, [])
    },
  },

  mounted() {
    this.$setTimeout(() => {
      this.updateRefs()
    })
  },
  methods: {
    getMousePosition(e) {
      if (!this.progressBar) {
        return { x: 0, y: 0 }
      }
      const progrssBarRect = this.progressBar?.getBoundingClientRect()

      return {
        x: e.clientX - progrssBarRect.x,
        y: e.clientY - progrssBarRect.y,
      }
    },
    onMouseEnter() {
      this.hovered = true
    },
    onMouseMove(e) {
      this.mousePosition = this.getMousePosition(e)
    },
    onMouseLeave() {
      this.hovered = false
      this.isDraggingCursor = false
    },
    onCursorMouseDown() {
      this.isDraggingCursor = true
    },
    onCursorMouseUp() {
      this.isDraggingCursor = false
    },
    onProgressBarClick() {
      this.$emit("update-frame-index", this.hoveredFrameIndex)
      this.$emit("seek", this.hoveredFrameIndex)
    },
    updateRefs() {
      this.progressBar = this.$refs.progressBar
    },
    getChunkStyle(start, end) {
      const [startPercentage, endPercentage] = [
        (100 * start) / (this.frames.length - 1),
        (100 * end) / (this.frames.length - 1),
      ]

      return {
        left: `${startPercentage}%`,
        width: `${endPercentage - startPercentage}%`,
      }
    },
  },
}
</script>

<style lang="scss">
$base-bg: #6c737a;
$progress-bg: #0090ff;
$hover-bg: #bbb;
$chunk-bg: #9bcdff;
$tooltip-bg: #333;
$transition-duration: 0.2s;
$bar-height: 7px;

.player-progress {
  position: relative;
  z-index: 2;
  &__background {
    background: $base-bg;
  }
  &__progress {
    background: $progress-bg;
    transition: $transition-duration ease-out;
    z-index: 2;
  }
  &__cursor {
    right: 0;
    height: 12px;
    width: 12px;
    opacity: 0;
    z-index: 3;
    position: absolute;
    border-radius: 50%;
    transition: $transition-duration;
    background: $progress-bg;
    transform: translate(3px, -3px);
  }
  &__hover-bar {
    background: $hover-bg;
    transition: $transition-duration background;
    &__cursor {
      opacity: 1;
    }
    &--hidden {
      background: $base-bg;
    }
  }
  &__chunk {
    z-index: 1;
    &--preloaded {
      background: $chunk-bg;
    }
  }
  &__tooltip {
    opacity: 1;
    position: absolute;
    transition: $transition-duration opacity;
    text-shadow: 0 0 4px rgb(0 0 0 / 75%);
    transform: translate(-50%, -150%);
    user-select: none;
    &--hidden {
      opacity: 0;
    }
  }
  &__background,
  &__progress,
  &__chunk,
  &__hover-bar,
  &__ticks {
    height: $bar-height;
    position: absolute;
  }
  &__bar {
    transition: $transition-duration opacity;
    opacity: 0.6;
  }
  &__tick {
    height: $bar-height;
    width: 1px;
    background: #0064b1;
  }
}

.player--focused {
  .player-progress {
    &__bar {
      opacity: 0.8;
    }
    &__cursor {
      opacity: 1;
    }
  }
}
</style>
