<template>
  <div ref="eventsMarkers" class="player-progress__markers">
    <div v-for="(event, index) in events" :key="`marker-${event.id}-{${index}`">
      <!-- Markers indicator -->
      <div
        class="player-progress__marker player-actions__text white--text darken-2 subtitle-2"
        :class="getMarkerClass(event, index)"
        :style="getMarkerPosition(event)"
        @mouseenter="onMarkerMouseEnter(event)"
        @mouseleave="onMarkerMouseLeave(event)"
        @mouseup="onMarkerMouseUp(event)"
      >
        <ImagePlayerMarkerTooltip
          v-if="showMarkerTooltip"
          :item="events[index]"
          class="player-progress__marker-tooltip"
          :class="{
            'player-progress__marker-tooltip--hidden':
              !activeEventsIndexes.includes(index),
          }"
          v-bind="$attrs"
        />
      </div>

      <!-- Markers hover area -->
      <span
        :key="`area-${event.frameIndex}`"
        class="player-progress__marker-hover-area cursor-pointer"
        :style="getMarkerPosition(event)"
        @mouseenter="onMarkerMouseEnter(event)"
        @mouseleave="onMarkerMouseLeave(event)"
        @mouseup="onMarkerMouseUp(event)"
      />
    </div>
  </div>
</template>

<script>
import ImagePlayerMarkerTooltip from "@evercam/shared/components/imagePlayer/ImagePlayerMarkerTooltip"

export default {
  name: "ImagePlayerMarkers",
  components: {
    ImagePlayerMarkerTooltip,
  },
  props: {
    frames: {
      type: Array,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    showMarkerTooltip: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      isGateEventsLoading: false,
    }
  },
  computed: {
    activeEventsIndexes() {
      return this.events.reduce((acc, m, index) => {
        return m.isActive ? [...acc, index] : acc
      }, [])
    },
  },
  watch: {
    activeEventsIndexes(indexes) {
      if (indexes.length < 2) {
        return
      }
      this.$setTimeout(this.preventEventsThumbnailsOverlap, 500)
    },
  },
  created() {
    this.$root.$on(
      "is-gate-events-loading",
      (value) => (this.isGateEventsLoading = value)
    )
  },
  methods: {
    onMarkerMouseEnter(event) {
      this.$emit("marker-active", event)
    },
    onMarkerMouseLeave(event) {
      this.$emit("marker-reset", event)
    },
    onMarkerMouseUp(event) {
      this.$emit("marker-selected", event)
    },
    getMarkerClass(event, index) {
      return {
        "player-progress__marker--highlighted":
          this.activeEventsIndexes.includes(index),
        ...(event.cssClass || {}),
      }
    },
    getMarkerPosition({ frameIndex }) {
      const xPercent = (100 * frameIndex) / (this.frames.length - 1)
      const offset = xPercent > 2 ? 5 : 0

      return {
        left: `calc(${xPercent}% - ${offset}px)`,
      }
    },
    /**
     * When more than one event marker is displayed and highlighted
     * their corresponding thumbnail images may overlap if they're too close.
     * This function will prevent this overlap by applying a css transform
     */
    preventEventsThumbnailsOverlap() {
      const container = this.$refs.eventsMarkers
      const visibleThumbnails = Array.from(
        container?.querySelectorAll(
          ".player-progress__marker--highlighted .player-progress__marker-tooltip"
        )
      )

      if (visibleThumbnails.length !== 2) {
        return
      }

      const isProcessed =
        visibleThumbnails[0].style.left || visibleThumbnails[0].style.left
      if (isProcessed) {
        return
      }

      const thumbnailA = visibleThumbnails[0]?.getBoundingClientRect()
      const thumbnailB = visibleThumbnails[1]?.getBoundingClientRect()
      const intersectionRight = thumbnailA.right - thumbnailB.left
      const intersectionLeft = thumbnailB.right - thumbnailA.left
      if (intersectionRight <= 0 || intersectionLeft <= 0) {
        return
      }

      const padding = 5
      const isRightIntersection = intersectionRight > 0
      visibleThumbnails[isRightIntersection ? 0 : 1].style.left = `-${
        intersectionRight / 2 + padding
      }px`
      visibleThumbnails[isRightIntersection ? 1 : 0].style.left = `${
        intersectionRight / 2 + padding
      }px`
    },
  },
}
</script>

<style lang="scss">
$marker-bg: var(--v-warning-base);
$marker-bg--highlighted: var(--v-success-base);
$transition-duration: 0.2s;
$bar-height: 7px;
$marker-width: 3px;

.player-progress {
  &__marker {
    background: $marker-bg;
    width: $marker-width;
    border-radius: 1.5px;

    &:hover,
    &--highlighted {
      background: $marker-bg--highlighted;
    }
  }

  &__marker-hover-area {
    position: absolute;
    top: 0;
    height: 32px;
    width: $marker-width;
  }

  &__marker {
    height: $bar-height;
    position: absolute;
    opacity: 1;
    transition: 0.2s;
  }

  &__marker-tooltip {
    transition: 0.2s;

    &--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}
</style>
