<template>
  <!-- Event Thumbnail -->
  <div class="marker-tooltip" :style="tooltipStyle" @mousemove.stop="() => {}">
    <div
      class="marker-tooltip__label py-1 px-2 grey darken-4 white--text elevation-4"
    >
      {{ item.label }}
    </div>
    <v-img
      ref="vImg"
      eager
      :src="item.thumbnailUrl"
      contain
      width="220"
      max-width="250"
      :min-height="minHeight"
      class="marker-tooltip__img elevation-4"
      @load="onImageLoaded"
    >
      <template #placeholder>
        <div class="h-100 w-100 d-flex justify-center align-center">
          <EvercamLoadingAnimation size="ThreeXl" />
        </div>
      </template>
    </v-img>
  </div>
</template>

<script>
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"

export default {
  name: "ImagePlayerMarkerTooltip",
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    tooltipStyle: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      minHeight: 135,
    }
  },
  methods: {
    onImageLoaded() {
      const imgHeight = this.$refs.vImg?.image?.height
      if (imgHeight) {
        this.minHeight = imgHeight
      }
    },
  },
}
</script>

<style lang="scss">
$item-width: 220px;
$border-color: #333d;
$border-radius: 3px;

.marker-tooltip {
  width: $item-width;
  &__label {
    width: $item-width;
    border: 1px solid $border-color;
    border-bottom: none;
    border-radius: $border-radius;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    &.grey.grey {
      border-color: $border-color !important;
    }
  }
  &__img {
    border: 1px solid $border-color;
    border-top: none;
    border-radius: $border-radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  transition: 0.15s ease-out;
  border-radius: 3px;
  position: absolute;
  top: -12px;
  left: 0;
  transform: translate(-50%, -100%);
  z-index: 4;
}
</style>
